@charset "UTF-8";
@use "_mixins/break-points" as *;
@use "base" as *;
/* CSS Document */

@include media(pc) {
    /* 背景画像 */
    .first {
      background-image: url(../img/bg_01_pc.jpg);
      background-size: cover;
    }
  }
  @include media(pc_s) {
    .top-cont02 {
      background-image: url(../img/bg_02_pc.jpg);
      background-size: cover;
    }
    .top-cont05 {
      background-image: url(../img/bg_03_pc.jpg);
      background-size: cover;
    }
  }
