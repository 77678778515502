@charset "UTF-8";
/*	flex
---------------------------------------------------*/
/* aspect */
/* ofi */
@media print, screen and (min-width: 1920px) {
  /*====================================================================/
  	/*-------------------full PCスタイル-------------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 1257px) {
  /*====================================================================/
  	/*-------------------PCスタイル-------------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 977px) {
  /*====================================================================/
  	/*-------------------小さいPCスタイル----------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 561px) { /*====================================================================/
	/*------------------タブレットスタイル----------------
	/====================================================================*/
  html {
    width: 100%;
  }
}
@media print, screen and (min-width: 376px) {
  /*====================================================================/
  	/*------------------iPhone 以上----------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 1256px) {
  /*====================================================================/
  	/*------------------PC以下---------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 976px) {
  /*====================================================================/
  	/*------------------スマホタブレット共通スタイル---------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 560px) {
  /*====================================================================/
  	/*------------------スマホのみスタイル----------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
@media screen and (max-width: 375px) {
  /*====================================================================/
  	/*------------------iPhone 以下----------------
  	/====================================================================*/
  html {
    width: 100%;
  }
}
/* CSS Document */
@media print, screen and (min-width: 1257px) {
  /* 背景画像 */
  .first {
    background-image: url(../img/bg_01_pc.jpg);
    background-size: cover;
  }
}
@media print, screen and (min-width: 977px) {
  .top-cont02 {
    background-image: url(../img/bg_02_pc.jpg);
    background-size: cover;
  }
  .top-cont05 {
    background-image: url(../img/bg_03_pc.jpg);
    background-size: cover;
  }
}